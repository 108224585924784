import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';

type FaqAccordionProps = {
  faqs: {
    question: string;
    answer: string;
  }[];
};

export function FaqAccordion({ faqs }: FaqAccordionProps) {
  return (
    <div className="mx-auto max-w-3xl">
      <Accordion type="single" collapsible className="space-y-4">
        {faqs.map(({ question, answer }) => (
          <AccordionItem
            key={question}
            value={question}
            className="group rounded-lg border-none bg-[rgba(6,135,251,0.10)]"
          >
            <AccordionTrigger className="flex w-full items-center justify-between p-6 text-left hover:no-underline [&>svg]:size-4">
              <span className="text-lg font-medium text-gray-900">
                {question}
              </span>
            </AccordionTrigger>
            <AccordionContent className="p-6 pt-0 text-[rgba(3,49,75,0.6)]">
              <p>{answer}</p>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}
